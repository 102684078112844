import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; // Add useNavigate
import clientConfig from '../../client-config';
import SmallImg from '../../assets/images/smlimg.png';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NewsSection = () => {
  const [posts, setPosts] = useState([]);
  const [authToken, setAuthToken] = useState('');
  const navigate = useNavigate(); // Initialize navigate

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
      responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // infinite: true,
          // dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  

  // var settings = {
  //   dots: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 3,
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };



  const getAuthToken = async () => {
    try {
      const response = await axios.post(`${clientConfig.siteUrl}/wp-json/jwt-auth/v1/token`, {
        username: 'admin', // replace with your username
        password: 'ogPcbCA#9uR%i&9wKt'  // replace with your password
      });
      setAuthToken(response.data.token);
    } catch (err) {
      console.error('Error fetching auth token:', err);
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (!authToken) {
          await getAuthToken();
        }

        const categoryResponse = await axios.get(
          `${clientConfig.siteUrl}/wp-json/wp/v2/categories?slug=news`,
          {
            headers: {
              'Authorization': `Bearer ${authToken}`
            }
          }
        );

        const newsCategory = categoryResponse.data[0];

        if (newsCategory) {
          const response = await axios.get(
            `${clientConfig.siteUrl}/wp-json/wp/v2/posts?categories=${newsCategory.id}&per_page=3`,
            {
              headers: {
                'Authorization': `Bearer ${authToken}`
              }
            }
          );

          const postsWithDetails = await Promise.all(response.data.map(async (post) => {
            if (post.featured_media) {
              try {
                const mediaResponse = await axios.get(
                  `${clientConfig.siteUrl}/wp-json/wp/v2/media/${post.featured_media}`,
                  {
                    headers: {
                      'Authorization': `Bearer ${authToken}`
                    }
                  }
                );
                post.featured_media_url = mediaResponse.data.source_url;
              } catch (mediaError) {
                console.error(`Error fetching media for post ${post.id}:`, mediaError);
                post.featured_media_url = SmallImg;
              }
            } else {
              post.featured_media_url = SmallImg;
            }

            return post;
          }));

          setPosts(postsWithDetails);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [authToken]);

  const truncateExcerpt = (excerpt, wordLimit) => {
    const words = excerpt.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return excerpt;
  };


  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3, // Number of slides to show
  //   slidesToScroll: 1, // Number of slides to scroll
  //   responsive: [
  //     {
  //       breakpoint: 1024, // Width threshold for tablet view
  //       settings: {
  //         slidesToShow: 2,
  //       }
  //     },
  //     {
  //       breakpoint: 600, // Width threshold for mobile view
  //       settings: {
  //         slidesToShow: 1,
  //       }
  //     }
  //   ]
  // };


  return (
    <section className="explore-section overflow-hid">
      <h2 className="explore-more">Explore more news</h2>
      {/* <div className="explore-row"> */}
      <Slider {...settings}>
        {posts.map(post => (
          <article className="col column-third" key={post.id}>
            <div className="explore-box box-c" style={{
              height : "659px",
              overflow : 'hidden'
            }}>
              <h3 className="explore-title" >
                <Link to={`/post/${post.id}`} className='truncate-text' >{post.title.rendered}</Link>
              </h3>
              
              <div className="explore-extra-content content-image" style={{
                height : "400px",
                overflow : "hidden"
              }}>
                <img src={post.featured_media_url} alt={post.title.rendered} />
              </div>
              <p className="explore-description overflow-hid" dangerouslySetInnerHTML={{ __html: truncateExcerpt(post.excerpt.rendered, 11) }} />
            </div>
          </article>
        ))}
      </Slider>
      {/* </div> */}
      
    </section>
  );
};

export default NewsSection;
