import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/ReadyStarted.css"; // Import custom CSS for ReadyStarted component

const ReadyStarted = () => {
  return (
    <div className="ready-started py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 text-left">
            <h2 className="ready-title">Ready to Get Started?</h2>
            <p className="ready-description">Sign Up or Contact Us</p>
          </div>
          <div className="col-md-6 text-right">
            <button
              className="btn btn-dark me-3 px-3 py-2 rounded-pill"
              onClick={() =>
                (window.location.href =
                  "https://calendly.com/d/ck5g-53m-559/aira-demo")
              }
            >
              Book A Demo
            </button>
            <a href="mailto:contact@helloaira.io" title="contact@helloaira.io">
              <button className="btn contact-us-btn px-3 py-2 rounded-pill">
                Contact Us
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadyStarted;
