import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "@fortawesome/fontawesome-free/css/all.min.css";
import GlobalStyles from "./styles/GlobalStyles";
import Header from "./Common/Header";
import Blog from "./pages/Blog";
import News from "./components/blog/Category";
import PostDetail from "./components/blog/PostDetail";
import Home from "./pages/Home"; // Import the new Home component
import { AuthProvider } from "./context/authContext";

function App() {
  return (
    <div className="App">
    <AuthProvider>
        <GlobalStyles />
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} /> {/* Add the home route */}
            <Route path="/blogs" element={<Blog />} />
            <Route path="/news" element={<News />} />
            <Route path="/post/:id" element={<PostDetail />} />{" "}
            {/* Update the route path */}
          </Routes>
        </Router>
    </AuthProvider>
    </div>
  );
}

export default App;
