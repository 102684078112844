import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import clientConfig from '../../client-config';
import RecImg from '../../assets/images/rec.png';

const Category = () => {
  const [posts, setPosts] = useState([]);
  const [authToken, setAuthToken] = useState('');
  
  // Function to get JWT token
  const getAuthToken = async () => {
    try {
      const response = await axios.post(`${clientConfig.siteUrl}/wp-json/jwt-auth/v1/token`, {
        username: 'admin', // replace with your username
        password: 'ogPcbCA#9uR%i&9wKt'  // replace with your password
      });
      setAuthToken(response.data.token);
    } catch (err) {
      console.error('Error fetching auth token:', err);
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (!authToken) {
          await getAuthToken();
        }

        const response = await axios.get(
          `${clientConfig.siteUrl}/wp-json/wp/v2/posts?per_page=3`,
          {
            headers: {
              'Authorization': `Bearer ${authToken}`
            }
          }
        );

        const postsWithDetails = await Promise.all(response.data.map(async (post) => {
          if (post.featured_media) {
            try {
              const mediaResponse = await axios.get(
                `${clientConfig.siteUrl}/wp-json/wp/v2/media/${post.featured_media}`,
                {
                  headers: {
                    'Authorization': `Bearer ${authToken}`
                  }
                }
              );
              post.featured_media_url = mediaResponse.data.source_url;
            } catch (mediaError) {
              console.error(`Error fetching media for post ${post.id}:`, mediaError);
              post.featured_media_url = RecImg;
            }
          } else {
            post.featured_media_url = RecImg;
          }

          if (post.tags.length > 0) {
            try {
              const tagResponse = await axios.get(
                `${clientConfig.siteUrl}/wp-json/wp/v2/tags?include=${post.tags.join(',')}`,
                {
                  headers: {
                    'Authorization': `Bearer ${authToken}`
                  }
                }
              );
              post.tagName = tagResponse.data.map(tag => tag.name).join(', ');
            } catch (tagError) {
              console.error(`Error fetching tag for post ${post.id}:`, tagError);
              post.tagName = "";
            }
          } else {
            post.tagName = "";
          }

          return post;
        }));

        setPosts(postsWithDetails);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [authToken]);

  return <MainContent posts={posts} />;
};

const MainContent = ({ posts }) => {
  const firstTwoPosts = posts.slice(0, 2); // First two posts
  const lastPost = posts[2]; // Last post

  return (
    <>
    <>
      {firstTwoPosts.map((post, index) => (
        <div className='footer-container'>
        <div className="main-content" key={post.id}>
          <div className="content-row ">
            {index % 2 === 0 ? (
              <>
                <section className="column-half" style={{
                  display: "flex",
                  alignItems : "center",
                  justifyContent: "center",
                  position : "relative",
                  height : "100% !important"
                }} >
                  <div className="text-content" style={{
                    height: "100% !important",
                    display : "flex",
                    alignItems: "center",
                    justifyContent : "center"
                  }}>
                    <div className="badge-ai" style={{
                      position: "absolute",
                      top : 5,
                      left : 5
                    }}>{post.tagName}</div>
                    <h2 className="headline truncate-text-head">
                      <Link to={`/post/${post.id}`}>{post.title.rendered}</Link>
                    </h2>
                    {/* <div className=''> */}
                    <p className="description description-text truncate-text truncate-remove  text-light" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                    {/* </div> */}
                  </div>
                </section>
                <aside className="column-half" style={{
                  display : "flex",
                  alignItems : "center",
                  justifyContent : "center"
                }}>
                <Link to={`/post/${post.id}`}>
                  <img  src={post.featured_media_url || RecImg} alt={post.title.rendered} className='width-style-image' />
                  </Link>
                  {/* <img  src={post.featured_media_url || RecImg} alt={post.title.rendered} /> */}
                </aside>
              </>
            ) : (
              <>
                <aside className="column-half" style={{
                  display : "flex",
                  alignItems : "center",
                  justifyContent : "center"
                }}>
                   <Link to={`/post/${post.id}`}>
                   <img src={post.featured_media_url || RecImg} alt={post.title.rendered} className='width-style-image' />
                  </Link>
                  {/* <img src={post.featured_media_url || RecImg} alt={post.title.rendered} /> */}
                </aside>
                <section className="column-half" style={{
                  display: "flex",
                  alignItems : "center",
                  justifyContent: "center",
                  position : "relative",
                  height : "100% !important"
                }} >
                  <div className="text-content" style={{
                    height: "100% !important",
                    display : "flex",
                    alignItems: "center",
                    justifyContent : "center"
                  }}>
                    <div className="badge-review" style={{
                      position: "absolute",
                      top : 5,
                      left : 5
                    }}>{post.tagName}</div>
                    <h2 className="headline truncate-text-head head-med">
                      <Link to={`/post/${post.id}`}>{post.title.rendered}</Link>
                    </h2>
                    <p className="description description-text truncate-text truncate-remove  text-light" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                  </div>
                </section>
              </>
            )}
          </div>
        </div>
        </div>
      ))}
      
    </>
      {lastPost && (
        <section className="footer-container">
           <div className="main-content" key={lastPost.id}>
           <div className="content-row">
          <section className="column-half" style={{
                  display: "flex !important",
                  alignItems : "center !important",
                  justifyContent: "center !important",
                  position : "relative !important",
                  height : "100% !important"
                }} >
                  <div className="text-content" style={{
                    height: "100% !important",
                    display : "flex",
                    alignItems: "center",
                    justifyContent : "center"
                  }}>
                <div className="badge-software">{lastPost.tagName}</div>
                <h2 className="headline truncate-text-head">
                  <Link to={`/post/${lastPost.id}`}>{lastPost.title.rendered}</Link>
                  </h2>
                <p className="description description-text truncate-text truncate-remove  text-light" dangerouslySetInnerHTML={{ __html: lastPost.excerpt.rendered }} />
              </div>
            </section>
            <aside className="column-half">
                   <Link to={`/post/${lastPost.id}`}>
                   <img src={lastPost.featured_media_url || RecImg} alt={lastPost.title.rendered} className='width-style-image' />
                  </Link>
                  {/* <img src={post.featured_media_url || RecImg} alt={post.title.rendered} /> */}
                </aside>
          </div>
          </div>
        </section>
      )}
      <Link to={'/post'} className="view-more-button view-button">
      <div>View more</div>
      </Link>
    </>
  );
};

export default Category;

