import React from 'react';
import Atrate from '../../assets/images/atrate.png';

const BannerSection = () => (
  <section className="content-section contentSection overflow-vis">
    <article className="section-highlight flex-box overflow-vis">
      <div className="section-content">
        <h1 className="title titleSize" >What’s New in Aira</h1>
        <p className="subtitle">Stay updated with all our latest posts here!</p>
        <div className="icon-container bottom-icon">
          <img loading="lazy" src={Atrate} className="icon" alt="icon" />
        </div>
      </div>
    </article>
  </section>
);

export default BannerSection;
