import React, { useCallback, useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/NewAira.css";
import FaArrowRight from "../assets/icons/box-arrow-icon.png";
import socialImageOne from "../assets/images/social-sm1.png";
import { listPosts } from "../api/post";
import { useAuth } from "../context/authContext";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
import axios from "axios";
import clientConfig from "../client-config";

dayjs.extend(relativeTime);

const NewAira = () => {
  const { authToken } = useAuth();
  const [blogs, setBlogs] = useState([]);

  const fetchBlogs = useCallback(async () => {
    if (authToken) {
      const response = await listPosts(authToken, { per_page: 10 });
      const blogsWithMedia = await Promise.all(
        response.map(async (blog) => {
          if (blog.featured_media) {
            try {
              const mediaResponse = await axios.get(
                `${clientConfig.siteUrl}/wp-json/wp/v2/media/${blog.featured_media}`,
                {
                  headers: {
                    Authorization: `Bearer ${authToken}`,
                  },
                }
              );
              blog.featured_media_url = mediaResponse.data.source_url;
            } catch (mediaError) {
              console.error(
                `Error fetching media for blog ${blog.id}:`,
                mediaError
              );
              blog.featured_media_url = null;
            }
          } else {
            blog.featured_media_url = null;
          }
          return blog;
        })
      );
      setBlogs(blogsWithMedia);
    }
  }, [authToken]);

  useEffect(() => {
    fetchBlogs();
  }, [fetchBlogs]);
  const cards = useMemo(
    () =>
      [...blogs]?.map((blog) => (
        {
        id: blog.id,
        type: "Announcing",
        date: blog.date ? dayjs(blog.date).fromNow() : "Unknown",
        title: blog.title.rendered,
        description: blog.excerpt.rendered,
        images: blog.featured_media_url
          ? [blog.featured_media_url]
          : [socialImageOne],
        author: "AIRA",
        heading: blog.title.rendered,
      })) ?? [],
    [blogs]
  );

  function cleanText(html) {
    // Remove HTML tags
    let text = html.replace(/<\/?[^>]+(>|$)/g, ""); 
    
    // Replace HTML entities with spaces or other relevant characters
    text = text.replace(/&nbsp;/g, " ").replace(/&hellip;/g, "...");
  
    return text.trim(); // Remove any extra spaces from the beginning and end
  }

  const renderCard = (card) => {
    if (!card) {
      return null;
    }

    return (
      <div>
      <Link to={`/post/${card.id}`} className="pointer" >
        <div className="card custom-card mb-3">
          <div className="card-header gradient-bg">
            <div className="row">
              <div className="col-6 text-uppercase small text-dark">
                {card.type}
              </div>
              <div className="col-6 text-right small text-dark">
                <strong>{card.date}</strong>
              </div>
            </div>
            <h5
              className="card-title"
              style={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "1",
              }}
            >
              <strong dangerouslySetInnerHTML={{ __html: card.heading }} />
            </h5>
            <div style={{ height: "100px" }}>
              {card.images.map((image, imgIndex) => (
                <img
                  style={{ height: "100%" }}
                  src={image}
                  alt={card.title.rendered}
                  className="img-fluid"
                  key={imgIndex}
                />
              ))}
            </div>
          </div>
          <div className="card-body">
            <p
              className="card-text"
              style={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "4",
                fontWeight: "normal",
              }}
              // dangerouslySetInnerHTML={{ __html: card.description }}
            >
              {cleanText(card.description)}
            </p>

            <div className="card-footer">
              <span className="icon-circle"></span>
              <small className="text-muted">{card.author}</small>
            </div>
          </div>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col-12">
            <h3
              className="article-heading"
              style={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "1",
              }}
              dangerouslySetInnerHTML={{ __html: card.heading }}
            />

            <a href="/blogs" className="read-article">
              Read the article <img src={FaArrowRight} alt="Arrow icon" />
            </a>
          </div>
        </div>
        </Link>
      </div>
    );
  };

  return (
    <div className="new-aira py-5">
      <div className="container">
        <div className="row mb-4 align-items-center">
          <div className="col-md-6">
            <h2 className="section-heading">What’s new in Aira</h2>
          </div>
         
          <div className="col-md-6 text-right">
            <a href="/blogs" className="btn px-3 py-2 rounded-pill see-more-btn">See more blogs</a>
          </div>
        </div>

        <div className="row">
          {cards.map((card, i) => (
            <div
              className={
                (i % 3 === 0 ? "col-lg-12" : "col-lg-6") +
                " " +
                (i % 3 === 1 ? "col-xl-3" : "col-xl-3")
              }
              key={card.id}
            >
              {renderCard(card, i % 3 === 1 ? 2 : 1)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewAira;
