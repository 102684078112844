import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "../styles/PricingPlan.css"; // Import custom CSS for PricingPlan component
import CheckMark from "../assets/icons/Checkmark.png";

const PricingPlan = () => {
  const plans = [
    {
      name: "Small",
      price: "$99/mo",
      description: "+ Maximum 5 people",
      accounts: "1-5",
      integration: "",
      support: CheckMark,
      tier: "Tier 1",
      bgColor: "rgba(230, 230, 231, 1)", // RGBA equivalent of #86A6E9
    },
    {
      name: "Medium",
      price: "$199/mo",
      description: "+ Maximum 20 people",
      accounts: "5-20",
      integration: "",
      support: CheckMark,
      tier: "Tier 2",
      bgColor:
        "linear-gradient(90deg, rgba(134, 166, 233, 0.3) 0%, rgba(160, 103, 168, 0.3) 50%, rgba(237, 98, 69, 0.3) 100%)", // RGBA equivalent of #86A6E9
    },
    {
      name: "Large",
      price: "$499/mo",
      description: "+ Maximum 50 people",
      accounts: "20-50",
      integration: "",
      support: CheckMark,
      tier: "Tier 3",
      bgColor: "rgba(134, 166, 233, 0.3)", // RGBA equivalent of #86A6E9
    },
    {
      name: "Enterprise",
      price: "Custom",
      description: "+ Minimum 50 people",
      accounts: "50+",
      integration: CheckMark,
      support: CheckMark,
      tier: "Tier 4",
      bgColor: "rgba(134, 166, 233, 0.3)", // RGBA equivalent of #86A6E9
    },
  ];

  return (
    <div className="pricing-plan py-5">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <h2 className="text-center">Choose a pricing plan</h2>
          </div>
        </div>

        <div className="row text-center mb-3">
          <div className="col-3 textSize">
            <strong></strong>
          </div>
          <div className="col-3 textSize">
            <strong>Enjoy all our features up to</strong>
          </div>
          <div className="col-3 textSize">
            <strong>Custom integration</strong>
          </div>
          <div className="col-3 textSize">
            <strong>Dedicated support</strong>
          </div>
        </div>

        {plans.map((plan, index) => (
          <div
            className="row align-items-center mb-3 plan-row position-relative"
            key={index}
            style={{
              background: plan.bgColor,
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <div
              className="tier-label vertical-text"
              style={{
                position: "absolute",
                left: "-45px",
                fontSize: "1rem",
                background:
                  "linear-gradient(90deg, rgba(134, 166, 233, 0.8) 0%, rgba(160, 103, 168, 0.8) 50%, rgba(237, 98, 69, 0.8) 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                transform: "rotate(-90deg)",
                transformOrigin: "left top",
              }}
            >
              <strong>{plan.tier}</strong>
            </div>
            <div
              className={`col-3 p-3 ${
                index === plans.length - 1 ? "last-plan" : ""
              } flex-col`}
            >
              <div className="plan-name bg-white rounded d-inline-block px-3 py-2 planSize">
                {plan.name}
              </div>
              <div className="plan-price mt-2 textSize">{plan.price}</div>
              <div className="plan-description textSizeS">
                {plan.description}
              </div>
            </div>
            <div className="col-3 text-center">{plan.accounts}</div>
            <div className="col-3 text-center">
              <img src={plan.integration} alt="" width="15" />
            </div>
            <div className="col-3 text-center">
              <img src={plan.support} alt="" width="15" />
            </div>
          </div>
        ))}
        <div className="font-poppins">
          <p>
            *All tiers come with a dedicated customer support team to onboard
            you onto the AIRA Platform.
          </p>
          <button
            className="btn btn-dark rounded-pill px-3 py-2 mt-3"
            onClick={() =>
              (window.location.href =
                "https://calendly.com/d/ck5g-53m-559/aira-demo")
            }
          >
            Try Aira for free
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingPlan;
