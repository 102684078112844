import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../styles/SeamlessCommunication.css'; // Import custom CSS for SeamlessCommunication component
import SeamlessCommunicationImg from '../assets/images/Mentioning team.svg';
import SeamlessCommunicationImgThree from '../assets/images/Summaries.svg';

const SeamlessCommunication = () => {
  const cards = [
    {
      imgSrc: SeamlessCommunicationImg, // Replace with the actual image path
      title: 'Quickly grab team members attention in discussions',
      imgClass: 'card-img-first',
      bgColor: 'rgba(237, 98, 69, 1)' // RGBA equivalent of #ED6245
    },
    {
      gifSrc: 'https://giphy.com/embed/I95JszGTg8JGTKmkBm', // Replace the second card with a GIF URL
      title: 'Keep conversations organized with specific topics.',
      imgClass: 'card-img-second',
      bgColor: 'rgba(160, 103, 168, .47)' // RGBA equivalent of #A067A8
    },
    {
      imgSrc: SeamlessCommunicationImgThree, // Replace with the actual image path
      title: 'Generate concise summaries to keep everyone up-to-date.',
      imgClass: 'card-img-third',
      bgColor: 'rgba(134, 166, 233, 1)' // RGBA equivalent of #86A6E9
    }
  ];

  return (
    <div className="seamless-communication py-5">
      <div className="container">
        {/* Title and Paragraph Row */}
        <div className="row mb-5">
          <div className="col-12 col-md-8">
            <h2 className="text-dark seamtagAlign text-size">Seamless Communication</h2>
            <div className="">
            <p>
              Enhance your team's collaboration with Aira's advanced communication tools. Notify your working groups in real time by mentioning them in a conversation.
            </p>
          </div>
          </div>
        </div>

        {/* Cards Row */}
        <div className="row">
          {cards.map((card, index) => (
            <div className="col-12 col-md-4 mb-4" key={index}>
              <div className="card h-100 border-0 bg-transparent">
                <div className={`card-img-wrapper card-width ${card.imgClass}`} style={{ backgroundColor: card.bgColor }}>
                  {card.gifSrc ? (
                    <div style={{ width: '100%', height: 0, paddingBottom: '56%', position: 'relative' }}>
                      <iframe
                        src={card.gifSrc}
                        width="100%"
                        height="100%"
                        style={{ position: 'absolute', pointerEvents: 'none' }} // Prevents hover and clicks
                        frameBorder="0"
                        className="giphy-embed"
                        allowFullScreen
                      ></iframe>
                    </div>
                  ) : (
                    <img src={card.imgSrc} className="card-img-top img-fluid" alt="Card thumbnail" />
                  )}
                </div>
                <div className="card-body p-0">
                  <h5 className="card-title cardTitle text-dark">{card.title}</h5>
                  {/* Link text can be uncommented if needed */}
                  {/* <a href="#" className="card-link text-decoration-none text-primary d-flex align-items-center">
                    {card.linkText} <span className="ml-2"></span>
                  </a> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeamlessCommunication;
