import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import clientConfig from "../../client-config";
import RecImg from "../../assets/images/Rectangle.png"; // Adjust the path accordingly

const Category = () => {
  const [posts, setPosts] = useState([]);
  const [authToken, setAuthToken] = useState("");

  // Function to get JWT token
  const getAuthToken = async () => {
    try {
      const response = await axios.post(
        `${clientConfig.siteUrl}/wp-json/jwt-auth/v1/token`,
        {
          username: "admin", // replace with your username
          password: "ogPcbCA#9uR%i&9wKt", // replace with your password
        }
      );
      setAuthToken(response.data.token);
    } catch (err) {
      console.error("Error fetching auth token:", err);
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (!authToken) {
          await getAuthToken();
        }

        const response = await axios.get(
          `${clientConfig.siteUrl}/wp-json/wp/v2/posts?per_page=5`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const postsWithDetails = await Promise.all(
          response.data.map(async (post) => {
            if (post.featured_media) {
              try {
                const mediaResponse = await axios.get(
                  `${clientConfig.siteUrl}/wp-json/wp/v2/media/${post.featured_media}`,
                  {
                    headers: {
                      Authorization: `Bearer ${authToken}`,
                    },
                  }
                );
                post.featured_media_url = mediaResponse.data.source_url;
              } catch (mediaError) {
                console.error(
                  `Error fetching media for post ${post.id}:`,
                  mediaError
                );
                post.featured_media_url = RecImg; // Use placeholder image
              }
            } else {
              post.featured_media_url = RecImg; // Use placeholder image
            }

            if (post.tags.length > 0) {
              try {
                const tagResponse = await axios.get(
                  `${
                    clientConfig.siteUrl
                  }/wp-json/wp/v2/tags?include=${post.tags.join(",")}`,
                  {
                    headers: {
                      Authorization: `Bearer ${authToken}`,
                    },
                  }
                );
                post.tagName = tagResponse.data
                  .map((tag) => tag.name)
                  .join(", ");
              } catch (tagError) {
                console.error(
                  `Error fetching tag for post ${post.id}:`,
                  tagError
                );
                post.tagName = "";
              }
            } else {
              post.tagName = "";
            }

            return post;
          })
        );

        setPosts(postsWithDetails);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [authToken]);

  return <CategoryContent posts={posts} />;
};

const CategoryContent = ({ posts }) => {
  return (
    <>
      <div className="backblog">
        <main className="container-blog">
          <h1 className="text-light">News</h1>
          {posts.map((post, index) => (
            <div className="main-content" key={post.id}>
              <div className="content-row">
                {index % 2 === 0 ? (
                  <>
                    <section className="column-half">
                      <div className="text-content">
                        <div className="badge-ai">{post.tagName}</div>
                        <h2 className="headline">
                          <Link to={`/post/${post.id}`}>
                            {post.title.rendered}
                          </Link>
                        </h2>
                        <p
                          className="description text-light"
                          dangerouslySetInnerHTML={{
                            __html: post.excerpt.rendered,
                          }}
                        />
                      </div>
                    </section>
                    <aside className="column-half">
                      <img
                        src={post.featured_media_url || RecImg}
                        alt={post.title.rendered}
                      />
                    </aside>
                  </>
                ) : (
                  <>
                    <aside className="column-half">
                      <img
                        src={post.featured_media_url || RecImg}
                        alt={post.title.rendered}
                      />
                    </aside>
                    <section className="column-half">
                      <div className="text-content">
                        <div className="badge-review">{post.tagName}</div>
                        <h2 className="headline">
                          <Link to={`/post/${post.id}`}>
                            {post.title.rendered}
                          </Link>
                        </h2>
                        <p
                          className="description text-light"
                          dangerouslySetInnerHTML={{
                            __html: post.excerpt.rendered,
                          }}
                        />
                      </div>
                    </section>
                  </>
                )}
              </div>
            </div>
          ))}
        </main>
      </div>
    </>
  );
};

export default Category;
