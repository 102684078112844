import React, { useEffect } from "react";
import HeroBanner from "../components/HeroBanner";
import VideoSection from "../components/VideoSection";
// import HiredSection from '../components/HiredSection';
// import MatchJob from '../components/MatchJob';
import HiredEasily from "../components/HiredEasily";
import AccurateData from "../components/AccurateData";
import SeamlessCommunication from "../components/SeamlessCommunication";
import AiActions from "../components/AiActions";
import PricingPlan from "../components/PricingPlan";
import NewAira from "../components/NewAira";
import ReadyStarted from "../components/ReadyStarted";
import Footer from "../Common/Footer";
import { Element, scroller } from "react-scroll";
import { Helmet } from "react-helmet";

const Home = () => {
  useEffect(() => {
    // Scroll to id element on the page load
    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace("#", "");
      scroller.scrollTo(id, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Aira</title>
        <meta name="title" content="Aira" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta
          property="og:image"
          content="/assets/images/meta/aira_social_media_preview.png"
        />

        {/* <!-- Twitter --> */}
        <meta
          property="twitter:image"
          content="/assets/images/meta/aira_social_media_preview.png"
        />
      </Helmet>
      <HeroBanner />
      <VideoSection />
      {/* <HiredSection /> */}
      {/* <MatchJob /> */}
      <HiredEasily />
      <AiActions />
      <SeamlessCommunication />
      <AccurateData />
      <Element id="pricing-scroll">
        <PricingPlan />
      </Element>
      <NewAira />
      <ReadyStarted />
      <Footer />
    </div>
  );
};

export default Home;
