import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Poppins', sans-serif;
  },

  html {
  scroll-behavior: smooth;
}
`;

export default GlobalStyles;