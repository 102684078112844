import React from 'react';
import LinkedinIcon from '../../assets/images/linkedin.png';
import TwitterIcon from '../../assets/images/twitter.png';
import FacebookIcon from '../../assets/images/facebook.png';
import LogoImg from '../../assets/images/logo.png';
import PolygonImg from '../../assets/images/Polygon.png';

const SocialFollow = () => (
  <footer className="social-follow">
    <div className="social-icons">
      <div className="icons-row">
        <h3 className="follow-text">Follow us</h3>
        <img loading="lazy" src={LinkedinIcon} alt="LinkedIn" />
        <img loading="lazy" src={TwitterIcon} alt="Twitter" />
        <img loading="lazy" src={FacebookIcon} alt="Facebook" />
      </div>
    </div>
    <div className="bottom-border"></div>
    <div className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <a href="https://jolly-ground-085976c00.5.azurestaticapps.net/">
            <img loading="lazy" src={LogoImg} className="footer-logo" alt="logo" />
          </a>
          <a href="https://jolly-ground-085976c00.5.azurestaticapps.net/privacy" className="footer-link">Privacy and Terms</a>
          <a href="https://jolly-ground-085976c00.5.azurestaticapps.net/about" className="footer-link">About Us</a>
          <a href="https://jolly-ground-085976c00.5.azurestaticapps.net/products" className="footer-link">Products</a>
        </div>
        <div className="footer-secondary">
          <div className="footer-help">
            <div className="footer-help-icon">?</div>
            <a href="https://jolly-ground-085976c00.5.azurestaticapps.net/help" className="footer-help-text">Help</a>
          </div>
          <div className="footer-language">
            <span>English - United States</span>
            <img loading="lazy" src={PolygonImg} className="flag-icon" alt="flag" />
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default SocialFollow;
