import React from "react";
import { Helmet } from "react-helmet";
import ContentSection from "../components/blog/ContentSection";
import MainContent from "../components/blog/MainContent";
import NewsSection from "../components/blog/NewsSection";
import SocialFollow from "../components/blog/SocialFollow";
import "../styles/Blog.css"; // Import custom CSS for Blog component
import Footer from "../Common/Footer";

const Blog = () => (
  <div className="backblog blog-size">
    <Helmet>
      <title>Aira | Blog</title>
      <meta name="title" content="Aira | Blog" />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:url" content="https://helloaira.io/blogs" />
      <meta property="og:title" content="Aira | Blog" />
      <meta
        property="og:description"
        content="Explore Aira's latest insights and updates on AI-driven workflow automation, productivity tips, and industry trends. Stay informed with expert articles and guides designed to enhance your efficiency and success."
      />
      <meta
        property="og:image"
        content="/assets/images/meta/aira_blog_social_media_preview.png"
      />

      {/* <!-- Twitter --> */}
      <meta property="twitter:url" content="https://helloaira.io/blogs" />
      <meta property="twitter:title" content="Aira | Blog" />
      <meta
        property="twitter:description"
        content="Explore Aira's latest insights and updates on AI-driven workflow automation, productivity tips, and industry trends. Stay informed with expert articles and guides designed to enhance your efficiency and success."
      />
      <meta
        property="twitter:image"
        content="/assets/images/meta/aira_blog_social_media_preview.png"
      />
    </Helmet>
    <main className="container-blog">
      <ContentSection />
      <MainContent />
      <NewsSection />
      {/* <SocialFollow /> */}
      <Footer />
    </main>
  </div>
);

export default Blog;
