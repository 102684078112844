import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../styles/HiredEasily.css'; // Import custom CSS for HiredEasily component

const HiredEasily = () => {
  const cardTitles = ["99%", "80%", "10x"];
  const cardBody = [
    "Accurate data extraction for jobs and candidates",
    "Faster task completion",
    "Faster job market research using public search",
  ];

  return (
    <div className="hired-easily py-5">
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="col-12 text-center">
            <h1 className="text-dark">Hire faster with AI</h1>
          </div>
        </div>
        <div className="row">
          {cardTitles.map((title, index) => (
            <div className="col-12 col-md-6 col-lg-4 mb-4" key={index}>
              <div className="card h-100">
                <div className="card-body text-center">
                  <h5 className="card-title">{title}</h5>
                  <p className="card-text">{cardBody[index]}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HiredEasily;
