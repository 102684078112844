import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import clientConfig from "../../client-config"; // Adjust the path as necessary
import Atrate from "../../assets/images/atrate.png";
import Footer from "../../Common/Footer";
import NewAira from "../NewAira";
import NewsSection from "./NewsSection";

const PostDetail = () => {
  const { id: postId } = useParams(); // Use 'id' to match the route parameter
  const [post, setPost] = useState(null);
  const [authToken, setAuthToken] = useState("");

  const getAuthToken = async () => {
    try {
      const response = await axios.post(
        `${clientConfig.siteUrl}/wp-json/jwt-auth/v1/token`,
        {
          username: "admin",
          password: "ogPcbCA#9uR%i&9wKt",
        }
      );
      setAuthToken(response.data.token);
    } catch (err) {
      console.error("Error fetching auth token:", err);
    }
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (!authToken) {
          await getAuthToken();
        }

        const response = await axios.get(
          `${clientConfig.siteUrl}/wp-json/wp/v2/posts/${postId}?_embed=true`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setPost(response.data);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, [postId, authToken]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div>
    <div className="backblog">
      <main className="container-blog">
        <section className="content-section content-sec">
          <article className="section-highlight section-padding">
            <div className="section-content section-width overflow-hid">
              <img
                src={
                  post._embedded["wp:featuredmedia"]?.[0]?.source_url ||
                  "default_image_url"
                }
                alt={post.title.rendered}
                className="image-width"
              />
              {/* <h1 className="title">{post.title.rendered}</h1> */}
            </div>
          </article>
          <div className="icon-container icon-posi">
              <img loading="lazy" src={Atrate} className="icon" alt="icon" />
          </div>
        </section>
        <div className="heading-div font-poppins">
          <h1 className="title text-head">{post.title.rendered}</h1>
        </div>
        <div className="main-content text">
          <div
            className="text-light mt-5"
            dangerouslySetInnerHTML={{ __html: post.content.rendered }}
          />
        </div>
      </main>
      <div style={{
        width : "100%",
        display: "flex",
        alignItems : "center",
        justifyContent : "center"
      }}>
      <NewsSection/>
      </div>
    </div>
   
      <Footer/>
    </div>
  );
};

export default PostDetail;
