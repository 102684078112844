import axios from "axios";
import clientConfig from "../client-config";
import SmallImg from "../assets/images/smlimg.png";

export async function listPosts(authToken, query = {}) {
  if (!authToken) {
    throw new Error("No authToken provided");
  }

  try {
    const categoryResponse = await axios.get(
      `${clientConfig.siteUrl}/wp-json/wp/v2/categories?slug=news`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    const newsCategory = categoryResponse.data[0];

    if (newsCategory) {
      const response = await axios.get(
        `${clientConfig.siteUrl}/wp-json/wp/v2/posts?categories=${newsCategory.id}` +
          Object.entries(query)
            .map(([key, value]) => `&${key}=${value}`)
            .join(""),
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const postsWithDetails = await Promise.all(
        response.data.map(async (post) => {
          if (post.featured_media) {
            try {
              const mediaResponse = await axios.get(
                `${clientConfig.siteUrl}/wp-json/wp/v2/media/${post.featured_media}`,
                {
                  headers: {
                    Authorization: `Bearer ${authToken}`,
                  },
                }
              );
              post.featured_media_url = mediaResponse.data.source_url;
            } catch (mediaError) {
              console.error(
                `Error fetching media for post ${post.id}:`,
                mediaError
              );
              post.featured_media_url = SmallImg;
            }
          } else {
            post.featured_media_url = SmallImg;
          }

          return post;
        })
      );

      return postsWithDetails;
    }
  } catch (error) {
    console.error("Error fetching posts:", error);
  }
}
