import React from "react";
import { ReactSVG } from "react-svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Footer.css"; // Import custom CSS for Footer component
import lemon8Logo from "../assets/icons/lemon8-logo.svg";

const Footer = () => {
  return (
    <footer className="footer py-4">
      <div className="container">
        <div className="row mb-3">
          <div className="col-md-4 text-left">
            <ul className="footer-menu">
              <li>
                <a href="#product">Product</a>
              </li>
              <li>
                <a href="#features">Features</a>
              </li>
              <li>
                <a href="#resources">Resources</a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 text-center">
            <span className="footer-title">Aira</span>
          </div>
          <div className="col-md-4 text-right">
            <ul className="footer-menu">
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/blogs">Blog</a>
              </li>
              <li>
                <a href="/support">Support</a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="footer-divider" />

        <div className="row mb-3 justify-content-between align-items-center">
          <div className="col-md-4">
            <p>
              BlockchainLabs.ai Co., Ltd.
              <br />
              29 Floor, 253 Asok Montri Rd, Khlong Toei
              <br />
              Nuea, Watthana, Bangkok 10110
              <br />
              Tax ID 0105561179526
            </p>
          </div>
          <div className="col-md-4 text-center">
            <div className="row justify-content-center">
              <div className="col-auto">
                <a
                  target="_blank"
                  href="https://www.facebook.com/helloaira.io/"
                  className="social-icon text-decoration-none"
                >
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
              </div>
              <div className="col-auto">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/helloaira-io/"
                  className="social-icon text-decoration-none"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
              <div className="col-auto">
                <a
                  target="_blank"
                  href="https://www.tiktok.com/@helloaira.io"
                  className="social-icon text-decoration-none"
                >
                  <i class="fa-brands fa-tiktok"></i>
                </a>
              </div>
              <div className="col-auto">
                <a
                  target="_blank"
                  href="https://www.instagram.com/helloaira.io/"
                  className="social-icon text-decoration-none"
                >
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </div>
              <div className="col-auto">
                <a
                  target="_blank"
                  href="https://www.lemon8-app.com/aira_wn"
                  className="social-icon text-decoration-none"
                >
                  <i>
                    <ReactSVG src={lemon8Logo} />
                  </i>
                </a>
              </div>
            </div>
            <div className="col text-center mt-2">
              <span>&copy; 2023-2024</span>
              <a href="#privacy-terms" className="privacy-terms">
                {" "}
                Privacy - Terms
              </a>
              <p>&copy; helloaira.io 2024</p>
            </div>
          </div>
          <div className="col-md-4 text-right">
            <p>
              <a href="mailto:contact@helloaira.io">contact@helloaira.io</a>
            </p>
            <p>
              <a href="tel:+66908984052">+66908984052</a>
            </p>
          </div>
        </div>

        <hr className="footer-divider" />
      </div>
    </footer>
  );
};

export default Footer;
