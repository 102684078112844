import React, { useState } from 'react';
import arrowIcon from '../assets/icons/right.png';
import whatsAppIcon from '../assets/icons/line-whatsapp.png';
import '../styles/HeroBanner.css'; // Make sure to create this CSS file for custom styles
import '../styles/Popup.css'
import ShapeImg from '../assets/images/shape.png';
import LogoImg from '../assets/images/logo.png';
import VbtnImg from '../assets/images/vbtn.png';
import Rectangle89 from '../assets/images/Rectangle89.png';


const HeroBanner = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="container-fluid py-5 hero-banner">
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex align-items-center bg-light rounded-pill px-3 py-2 mb-3 feature-button">
          <span className="badge-gradient me-2">New</span>
          <span className="text-secondary feature-text">Updated search features</span>
          <img src={arrowIcon} alt="Arrow Icon" className="ms-2" />
        </div>
        <h1 className="text-black text-center mb-3 blog-size">Hello, I'm Aira</h1>
        <h2 className="text-black text-center mb-3 blog-size">Your AI-assisted recruitment ATS.</h2>
        {/* <p className="text-center description">
        Meet your new recruiting AI assistant
        </p> */}
        <button
          className="btn btn-dark rounded-pill px-4 py-2 mt-3"
          onClick={() => window.location.href = 'https://calendly.com/d/ck5g-53m-559/aira-demo'}
        >
          Book A Demo 
        </button>
        {/* <div className="d-flex justify-content-center mt-3">
          <img src={whatsAppIcon} alt="Icon 1" className="mx-2" />
        </div> */}
      </div>

      {showPopup && (
        <div className="popOverlay">
          <div className="popWrp">
            <a href="#!" className="close" onClick={togglePopup}><img src={ShapeImg} alt="Close" /></a>
            <div className="lftWrp">
              <div><img src={LogoImg} style={{ width: '13%' }} alt="Logo" /></div>
              <div className="rqs">Request a Demo</div>
              <form>
                <input className="emal" placeholder="Email" name="email" />
                <label className="chkwrp checkcontainer">One
                  <input type="checkbox" checked="checked" />
                  <span className="checkmark"></span>
                  I want to receive emails about product updates and announcements.
                </label>
                <button className="btnCon"><img src={VbtnImg} alt="Submit" /></button>
              </form>
              <div>
                Having issues with this form? <br />
                Contact sales@aira.work
              </div>
            </div>
            <div className="rgtWrp">
              <img src={Rectangle89} alt="Illustration" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroBanner;
