import React, { useRef } from 'react';
import YouTube from 'react-youtube';
import '../styles/VideoSection.css'; // Create this CSS file for custom styles

const VideoSection = () => {
  const videoRef = useRef(null);

  // const onPlayVideo = () => {
  //   if (videoRef.current) {
  //     videoRef.current.internalPlayer.playVideo();
  //   }
  // };

  // const onPauseVideo = () => {
  //   if (videoRef.current) {
  //     videoRef.current.internalPlayer.pauseVideo();
  //   }
  // };

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      controls: 0, // Disable all controls except play and pause
      modestbranding: 1, // Minimal YouTube branding
    },
  };

  return (
    <div className="container-fluid py-5 video-section">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10">
          <div className="video-wrapper">
            <YouTube
              videoId="bsba0FRTerg"  // Updated video ID
              opts={opts}
              ref={videoRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
